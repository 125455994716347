import React, {FC, useCallback, useEffect, useRef, useState} from 'react';

import LevelRange from '../components/LevelRange';

import {useCurrentState, usePowerOff, usePowerOn, useSetPercentageValue} from './appQueries';

import {colors, ws_url} from '../utils/constants';

// @ts-ignore
import classes from './app.module.scss';
import {usePageVisibility} from "../utils/hooks";

const grayColors = ['gray', 'gray']

const App: FC = () => {
    const [maxPwmValue, setMaxPwmValue] = useState<number>(100);
    const [isPwm, setIsPwm] = useState<boolean>(false);
    const [pwmValue, setPwmValue] = useState<number>(0);
    const [isFirstConnection, setIsFirstConnection] = useState(true);

    const pageVisibility = usePageVisibility();

    const [socket, setSocket] = useState<WebSocket | null>(null);

    useEffect(() => {
        if (socket === null && (pageVisibility || isFirstConnection)) {
            let timeout:NodeJS.Timeout | null = null;
            const createConnection = () => {
                try {
                    const connection = new WebSocket(`${ws_url}/ws`);
                    connection.onopen = () => {
                        setSocket(connection);
                        setIsFirstConnection(false);
                    };


                    connection.onclose = () => {
                        setSocket(null);
                        timeout = setTimeout(createConnection, 2_000)
                    }

                } catch (err) {
                    console.error(err);
                }
            }

            createConnection();

            return () => {
                if (timeout !== null) {
                    clearTimeout(timeout);
                }
            }
        }
    }, [
        socket,
        isFirstConnection,
        pageVisibility
    ]);

    useEffect(() => {
        if (socket !== null) {
            const handler = (event) => {
                const data = JSON.parse(event.data);

                setIsPwm(data.isPwm);
                setPwmValue(data.pwmState);
                setMaxPwmValue(data.maxPwmValue);
            }

            socket.addEventListener('message', handler);
            return () => {
                socket.removeEventListener('message', handler);
                socket.close();
            }
        }
    }, [socket]);

    const {} = useCurrentState(data => {
        setIsPwm(data.isPwm);
        setPwmValue(data.pwmState);
        setMaxPwmValue(data.maxPwmValue);
    });

    const {powerOn} = usePowerOn();
    const {powerOff} = usePowerOff();
    const {setPercentageValue, isPercentageValueSetting} = useSetPercentageValue();

    const handleOnClick = useCallback(() => {
        powerOn();
        setIsPwm(false);
    }, [powerOn]);

    const handleOffClick = useCallback(() => {
        powerOff();
        setIsPwm(false);
    }, [powerOff]);

    const lastPwmValueRef = useRef<number>(Math.floor(pwmValue));

    const handleChangePwm = useCallback((value: number) => {
        setPwmValue(value);
        setIsPwm(true);
        if (socket !== null) {
            const roundedValue = Math.floor(value);
            if (lastPwmValueRef.current !== roundedValue) {
                lastPwmValueRef.current = roundedValue;
                socket.send(JSON.stringify(roundedValue));
            }
        }
    }, [setPwmValue, socket]);

    const percentageValue = Math.round(pwmValue / maxPwmValue * 100);

    return (
        <main className={classes.main}>
            <div className={classes.separator}>
                <LevelRange
                    colors={colors}
                    maxValue={maxPwmValue}
                    minValue={0}
                    value={pwmValue}
                    onSetValue={handleChangePwm}
                    className={classes.range}
                    inactive={!isPwm}
                    disabled={socket === null || isPercentageValueSetting}
                />
                <div className={classes.buttons}>
                    <button className={classes.button} onClick={handleOnClick}>Зажечь</button>
                    <button
                        className={classes.button} onClick={() => setPercentageValue(percentageValue)}
                        disabled={isPwm && pwmValue > 0.2}
                    >
                        Установить <br/> {percentageValue}%
                    </button>

                    <button className={classes.button} onClick={handleOffClick}>Потушить</button>
                </div>
            </div>
        </main>
    )
}

export default App;