import React, {FC, useMemo} from 'react';

// @ts-ignore
import classes from './luminary.module.scss';

interface LuminaryProps {
	value: number
}

const Luminary: FC<LuminaryProps> = props => {
	const {value} = props;
	
	const raysValue = value ** 2;
	
	const raysReactNode = useMemo(() => {
		const result: React.ReactNode[] = [];
		
		for (let i = 0; i < 8; ++i) {
			result.push(<div className={classes.luminary_ray} key={i} style={{width: raysValue * 7}}/>)
		}
		
		return result;
	}, [raysValue])
	
	return (
		<div
			className={classes.luminary}
			style={{
				transform: `rotate(${(1-value) * -30}deg)`,
			}}
		>
			<div className={classes.luminary_center}>
				<div
					className={classes.luminary_center_capacity}
					style={{transform: `translateX(${(value * 15)+10}px)`}}
				/>
			</div>
			<div className={classes.luminary_rays}>
				{raysReactNode}
			</div>
		</div>
	)
}

export default Luminary;