import {useMutation, useQuery} from "react-query";
import {api_url} from "../utils/constants";

const powerOnRequest = async () => {
	const options:RequestInit = {
		method: 'POST'
	}
	
	return await fetch(`${api_url}/on`, options);
}

export const usePowerOn = () => {
	const mutation = useMutation(powerOnRequest);
	
	return {
		powerOn: mutation.mutate
	}
}

//

const powerOffRequest = async () => {
	const options:RequestInit = {
		method: 'POST'
	}
	
	return await fetch(`${api_url}/off`, options);
}

export const usePowerOff = () => {
	const mutation = useMutation(powerOffRequest);
	
	return {
		powerOff: mutation.mutate
	}
}

//

export interface TCurrentState {
	isPwm: boolean,
	pwmState: number,
	digitalState: boolean,
	maxPwmValue: number,
}

export const currentStateQueryKey = 'current_state';

const fetchCurrentState = async () => {
	const response = await fetch(`${api_url}/currentState`);
	return await response.json();
}

export const useCurrentState = (onSuccess: (data: TCurrentState) => void) => {
	const query = useQuery<TCurrentState, unknown, TCurrentState>(currentStateQueryKey, fetchCurrentState, {
		onSuccess,
	});
	
	return {
		isCurrentStateLoading: query.isLoading,
	}
}

// Set percentage value

const setPercentageValueRequest = async (percentage: number) => {
	const response = await fetch(`${api_url}/setPercentageLevel/${percentage}`, {
		method: 'POST'
	});
	return await response.json();
}

export const useSetPercentageValue = () => {
	const mutation = useMutation<TCurrentState, unknown, number>({
		mutationFn: setPercentageValueRequest,
	});

	return {
		setPercentageValue: mutation.mutate,
		isPercentageValueSetting: mutation.isLoading,
	}
}