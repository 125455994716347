import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';

import Luminary from './Luminary';

import {getSubColor, map, minmax, useCompositeClassName} from '../../utils/functions';

// @ts-ignore
import classes from './levelRange.module.scss';

interface LevelSliderProps {
	value: number,
	onSetValue: (value: number) => void,
	// tickPeriod: number,
	// tickStart: number,
	
	colors?: string[],
	minValue?: number,
	maxValue?: number,
	className?: string,
	disabled?: boolean,
	inactive?: boolean,
}

const LevelRange: FC<LevelSliderProps> = props => {
	const {
		colors = ['gray', 'black'],
		minValue = 0,
		maxValue = 100,
		value: providedValue,
		onSetValue,
		className: providedClassName,
		inactive,
		disabled,
	} = props;

	const [isChanging, setIsChanging] = useState<boolean>(false);

	useEffect(() => {
		setIsChanging(true);

		const timeout = setTimeout(() => setIsChanging(false), 2000);

		return () => clearTimeout(timeout);

	}, [providedValue]);

	const touchYPosToValue = useCallback((touchYPos: number, rect: DOMRect):number => {
		return minmax(map(touchYPos, rect.bottom, rect.top, minValue, maxValue), minValue, maxValue);
	},[minValue, maxValue]);
	
	const containerRef = useRef<HTMLDivElement>(null);
	
	const [isMouse, setIsMouse] = useState<boolean>(false);
	const [isTouched, setIsTouched] = useState<boolean>(false);
	
	const [stateValue, setStateValue] = useState<number>(0);
	
	const value = isTouched ? stateValue : providedValue;
	
	const normalized = (value - minValue) / (maxValue - minValue)
	
	const color = useMemo(() => {
		if (disabled) {
			return 'gray';
		}

		if (inactive) {
			return 'lightgray';
		}

		if (colors.length === 2) {
			return getSubColor(colors[0], colors[1], normalized);
		}
		
		const index = Math.floor(normalized*(colors.length-2));
		const colorA = colors[index];
		const colorB = colors[index+1];
		
		return getSubColor(colorA, colorB, (normalized * (colors.length-2)) % 1);
	},[normalized, colors, inactive, disabled])
	
	
	const handleChangeValue = useCallback((value: number) => {
		setStateValue(value);
		onSetValue(value)
	},[setStateValue, onSetValue]);
	
	const handleMouseDown = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
		const rect = event.currentTarget.getBoundingClientRect();
		
		handleChangeValue(touchYPosToValue(event.clientY, rect));
		
		setIsMouse(true);
		setIsTouched(true);
	},[
		setIsMouse,
		setIsTouched,
		touchYPosToValue,
	]);
	
	const handleTouchStart = useCallback((event: React.TouchEvent) => {
		const rect = event.currentTarget.getBoundingClientRect();
		const touch = event.touches[0];
		const touchY = touch.clientY;
		
		handleChangeValue(touchYPosToValue(touchY, rect));
		
		setIsMouse(false);
		setIsTouched(true);
	},[
		setIsMouse,
		setIsTouched,
		touchYPosToValue,
	]);
	
	useEffect(() => {
		const containerElem = containerRef.current;
		if (isTouched && containerElem !== null) {
			const rect = containerElem.getBoundingClientRect();
			
			if (isMouse) {
				const handleMove = (event: MouseEvent) => {
					handleChangeValue(touchYPosToValue(event.clientY, rect));
				}
				const handleUp = (event: MouseEvent) => {
					setIsTouched(false);
				}
				
				document.addEventListener('mousemove', handleMove);
				document.addEventListener('mouseup', handleUp);
				
				return () => {
					document.removeEventListener('mousemove', handleMove);
					document.removeEventListener('mouseup', handleUp);
				}
			} else {
				const handleMove = (event: TouchEvent) => {
					handleChangeValue(touchYPosToValue(event.touches[0].clientY, rect));
				}
				const handleUp = () => {
					setIsTouched(false);
				}
				
				document.addEventListener('touchmove', handleMove);
				document.addEventListener('touchend', handleUp);
				document.addEventListener('touchcancel', handleUp);
				
				return () => {
					document.removeEventListener('touchmove', handleMove);
					document.removeEventListener('touchend', handleUp);
					document.removeEventListener('touchcancel', handleUp);
				}
			}
			
		}
		
	},[
		isTouched,
		isMouse,
		handleChangeValue,
		minValue,
		maxValue,
		setIsTouched,
		touchYPosToValue,
	]);
	
	const className = useCompositeClassName(classes.border, providedClassName);
	
	return (
		<button
			className={className}
			style={{border: `1px solid ${color}`}}
			onMouseDown={disabled ? undefined : handleMouseDown}
			onTouchStart={disabled ? undefined : handleTouchStart}
			disabled={disabled}
		>
			<div className={classes.container} ref={containerRef}>
				<div
					className={classes.bar}
					style={{
						backgroundColor: color,
						// transition: isTouched ? 'none' : 'all 0.3s ease-in-out',
						transform: `translateY(${(1-normalized)*100}%)`,
					}}
				/>
				<div className={classes.level_decoration} data-active={isTouched || isChanging}>
					<Luminary value={normalized}/>
					<p className={classes.percentage}>
						{(normalized * 100).toFixed(1).padStart(4, '0')}%
					</p>
				</div>
			</div>
		</button>
	)
}

export default LevelRange;