import React from 'react';
import ReactDOM from 'react-dom';
import {QueryClientProvider} from 'react-query';
import * as serviceWorker from './serviceWorker';

import App from './App/App';

import {queryClient} from './utils/queryClient';

import './index.scss';


// const root = ReactDOM.createRoot(
// 	document.getElementById('root') as HTMLElement
// );
//
// root.render(
// 	<React.StrictMode>
// 		<QueryClientProvider client={queryClient}>
// 			<App/>
// 		</QueryClientProvider>
// 	</React.StrictMode>
// );

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<App/>
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root') as HTMLElement
)

serviceWorker.register();