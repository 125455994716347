
export const api_url = `${window.location.origin}`;
// export const api_url = `https://mnogolikiy.sytes.net`;

export const ws_url = `wss://${window.location.host}`;
// export const ws_url = `wss://mnogolikiy.sytes.net`;

export const colors = [
	'#587792',
	'#8db1ab',
	'#cee397',
	'#f2ed6f',
	'#f4e04d',
];


export const _colors = [
	'#355e7e',
	'#6d5b7a',
	'#c06c84',
	'#f17280',
	'#f8b195'
]