import rgba from 'color-rgba';
import {useMemo} from "react";

//

const colorsCache:Record<string, [number, number, number, number]> = {};

const _ = (valueA: number, valueB: number, scale: number) => (valueA * (1 - scale)) + (valueB * scale)

export const getSubColor = (colorA: string, colorB: string, scale: number) => {
	const colorArrayA = colorsCache[colorA] ?? (colorsCache[colorA] = rgba(colorA) ?? [0,0,0,0]);
	const colorArrayB = colorsCache[colorB] ?? (colorsCache[colorB] = rgba(colorB) ?? [0,0,0,0]);
	
	const data = [
		_(colorArrayA[0], colorArrayB[0], scale),
		_(colorArrayA[1], colorArrayB[1], scale),
		_(colorArrayA[2], colorArrayB[2], scale),
		_(colorArrayA[3], colorArrayB[3], scale),
	];
	
	return `rgba(${data.join(',')})`;
}

export const map = (inn: number, min1: number, max1: number, min2: number, max2: number) => (((inn - min1) / (max1 - min1)) * (max2 - min2)) + min2;
export const minmax = (inn: number, min: number, max: number): number => Math.min(Math.max(inn, min), max);

const compositeClassNames = (...classNames: (string | unknown)[]) => classNames.filter(className => (typeof className === 'string') && (className !== '')).join(' ')

export const useCompositeClassName = (...classNames:(string | unknown)[]) => useMemo(() => {
	return compositeClassNames(...classNames);
},classNames);